import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/Home',
    },
    {
        path: '/Home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../components/Home/Home.vue'),
    },
    {
        path: '/book',
        name: 'book',
        component: () => import(/* webpackChunkName: "Home" */ '../components/Home/book.vue'),
    },
    {
        path: '/jishuzhichi',
        name: 'jishuzhichi',
        component: () =>
            import(/* webpackChunkName: "Home" */ '../components/Home/jishuzhichi.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
